import React from "react";
import styled from "styled-components";

import { Maybe, SanityRecipe } from "@graphql-types";
import { Container } from "@util/standard";
import {
  gradient,
  colors,
  assets,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  colorsRGB,
} from "@util/constants";
import { BlocksContent, Image, Carousel, Button } from "@global";
import { CarouselLeftArrow } from "@shared/shared.styles";

const FeaturedRecipeContainer = styled(Container)`
  width: 100%;
  .slick-arrow,
  .slick-dots {
    z-index: 2;
    position: absolute;
  }
  h1,
  h2,
  h3,
  h4,
  p,
  a,
  div,
  span {
    color: ${colors.black};
  }
  .slick-prev {
    left: 60px;
  }
  .slick-next {
    right: 60px;
  }
  .slick-dots {
    bottom: 35px;
    text-align: right;
    padding: 0 25px;
    box-sizing: border-box;
    li {
      width: 9px;
      height: 9px;
      button {
        width: 9px;
        height: 9px;
        &:before {
          content: "";
          background: ${colors.white};
          border-radius: 100px;
          width: 9px;
          height: 9px;
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .slick-prev {
      left: 18px;
    }
    .slick-next {
      right: 18px;
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    .slick-dots {
      bottom: 17px;
      text-align: left;
      padding: 0 33px;
      li {
        width: 5px;
        height: 5px;
        margin: 0 3px;
        button {
          width: 5px;
          height: 5px;
          &:before {
            width: 5px;
            height: 5px;
          }
        }
      }
    }
  }
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: ${gradient.featuredRecipeOverlay};
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    background: ${colorsRGB.black(0.47)};
  }
`;

interface Props {
  recipes: Maybe<SanityRecipe>[];
}

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 1.2;
  margin: 0 0 12px;
  font-weight: 600;

  letter-spacing: 0.08em;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 14px;
  }
`;

const FeaturedTitle = styled.p`
  font-size: 30px;
  line-height: 1.2;
  margin: 0;
  font-weight: 700;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 25px;
  }
`;

const Meta = styled(Container)`
  margin-top: 0.8em;
  span {
    margin: 0 15px;
    display: inline-block;
    width: 1px;
    background: ${colors.black};
  }
`;

const NextIcon = (
  <CarouselLeftArrow>
    <img src={assets.arrowRight} alt="right-arrow" />
  </CarouselLeftArrow>
);

const PrevIcon = (
  <CarouselLeftArrow rightArrow>
    <img src={assets.arrowRight} alt="left-arrow" />
  </CarouselLeftArrow>
);

const FeaturedRecipe = ({ recipes }: Props) => {
  if (!recipes) return null;
  return (
    <FeaturedRecipeContainer flexDirection="column" width="100%">
      <Carousel
        hideArrow={false}
        hideDots={false}
        nextIcon={NextIcon}
        prevIcon={PrevIcon}
        adaptiveHeight={true}
      >
        {recipes.map(recipe => {
          if (!recipe) return;
          let sliderBg = recipe?.info?.sliderFeaturedImage?.asset
            ?.gatsbyImageData
            ? recipe?.info?.sliderFeaturedImage?.asset?.gatsbyImageData
            : recipe?.info?.featuredMedia?.image?.asset?.gatsbyImageData;
          return (
            <Container
              display="flex !important"
              flexDirection="column"
              key={`recipe-${recipe._key}`}
              position="relative"
            >
              <Image
                isBackground
                minHeight="400px"
                imageData={sliderBg}
                objectFit="cover"
              >
                <BackgroundOverlay></BackgroundOverlay>
                <Container
                  width="80%"
                  tabletWidth="80%"
                  mobileWidth="calc(100% - 140px)"
                  margin="0 auto"
                  alignItems="center"
                  padding="50px 0"
                  mobilePadding="100px 0"
                  position="relative"
                  zIndex={1}
                  color="white"
                >
                  <Container
                    padding="50px"
                    backgroundColor="yellow"
                    maxWidth="455px"
                    width="100%"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Subtitle>Featured Recipes</Subtitle>
                    <FeaturedTitle>{recipe?.info?.title}</FeaturedTitle>
                    <Meta>
                      {recipe?.info?.prepTime && (
                        <>{recipe.info.prepTime} mins Prep</>
                      )}
                      {recipe?.info?.prepTime && recipe?.info?.cookTime && (
                        <span></span>
                      )}
                      {recipe?.info?.cookTime && (
                        <>{recipe.info.cookTime} mins Cook</>
                      )}
                    </Meta>
                    <BlocksContent
                      blocks={recipe?.info?.recipeDescription?._rawBlocks}
                    />
                    <Button
                      text="View Recipe"
                      theme="transparent"
                      linkTo={`/recipe/${recipe?.info?.slug?.current}`}
                    />
                  </Container>
                </Container>
              </Image>
            </Container>
          );
        })}
      </Carousel>
    </FeaturedRecipeContainer>
  );
};

export default FeaturedRecipe;

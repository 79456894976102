import React from "react";
import styled from "styled-components";

import { Maybe, SanityCategory } from "@graphql-types";
import { Container, FlexGrid, P, H2 } from "@util/standard";
import { navigate } from "gatsby";
import { TABLET_BREAKPOINT, assets, colors } from "@util/constants";
import { Image, Carousel } from "@global";
import {
  CarouselLeftArrow,
  RelatedCollectionContainer,
} from "@shared/shared.styles";
import { useCheckScreenWidth } from "@util/hooks";

interface Props {
  categories: Maybe<SanityCategory>[];
}

const StyledContainer = styled(Container)`
  > div {
    width: 100%;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  cursor: pointer;
  width: 100%;
  min-height: 397px;
  flex-flow: column nowrap;
  justify-content: flex-end;
  &:before {
    transition: opacity 0.3s ease;
    content: "";
    background: ${colors.white};
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
  &:hover:before {
    opacity: 0.3;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    min-height: 248px;
  }
`;

const GradientContainer = styled(Container)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.55));
`;

const StyledTitleContainer = styled(Container)<{ isOpen?: boolean }>`
  border-bottom: 1px solid white;
  width: 100%;
  img {
    margin-left: 5px;
    transform: translateZ(0);
    transition-duration: 0.1s;
    transition-property: transform;
    transition-timing-function: ease-out;
  }
  &:hover {
    img {
      transform: translateX(4px);
    }
  }
`;

const CollectionItem = ({ category }: { category: SanityCategory }) => {
  const categoryMain = category.main;

  return (
    <StyledContainer width="33.333%" tabletWidth="279px">
      <Image
        isBackground
        imageData={categoryMain?.hero?.videoUrl?.image?.asset?.gatsbyImageData}
      >
        <ContentContainer
          onClick={() => navigate(`/shop-all/${categoryMain?.slug?.current}`)}
        >
          <GradientContainer></GradientContainer>

          <Container
            flexDirection="column"
            width="100%"
            padding="56px 48px"
            mobilePadding="34px 30px"
            alignItems="flex-start"
            zIndex={1}
          >
            <H2 color="white" margin="0px 0px 12px 0px">
              {categoryMain?.title}
            </H2>
            <StyledTitleContainer>
              <Container justifyContent="space-between" width="100%">
                <P
                  cursor="pointer"
                  fontSize={15}
                  tabletFontSize={12}
                  color="white"
                >
                  View all
                </P>
                <img src={assets.arrowRightWhite} width="10px" />
              </Container>
            </StyledTitleContainer>
          </Container>
        </ContentContainer>
      </Image>
    </StyledContainer>
  );
};

const NextIcon = (
  <CarouselLeftArrow>
    <img src={assets.arrowRight} alt="right-arrow" />
  </CarouselLeftArrow>
);

const PrevIcon = (
  <CarouselLeftArrow rightArrow>
    <img src={assets.arrowRight} alt="left-arrow" />
  </CarouselLeftArrow>
);

const RelatedCollections = ({ categories }: Props) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  return (
    <>
      {isTabletWidth || isMobileWidth ? (
        <RelatedCollectionContainer flexDirection="column" width="100%">
          <Carousel
            hideArrow={false}
            hideDots={true}
            nextIcon={NextIcon}
            prevIcon={PrevIcon}
            variableWidth={true}
            infinite={false}
          >
            {categories.map(category => {
              if (!category) return;
              return (
                <CollectionItem
                  key={category.main?.slug?.current}
                  category={category}
                />
              );
            })}
          </Carousel>
        </RelatedCollectionContainer>
      ) : (
        <FlexGrid gridMargin="14px">
          {categories.map(category => {
            if (!category) return;
            return (
              <CollectionItem
                key={category.main?.slug?.current}
                category={category}
              />
            );
          })}
        </FlexGrid>
      )}
    </>
  );
};

export default RelatedCollections;
